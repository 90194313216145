var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"special-message-banner",class:{
    'special-message-banner--guide': _vm.inside_user_guide,
  }},[_c('carousel',{attrs:{"autoplay":true,"autoplayTimeout":_vm.nextSlideSpeed,"paginationEnabled":true,"navigationEnabled":true,"perPageCustom":[[300, 1]],"navigationNextLabel":" ","navigationPrevLabel":" "}},_vm._l((_vm.assembled_banners),function(banner,index){return _c('slide',{key:`${index}-slide`,staticClass:"special-message-banner__slide"},[_c('section',{class:{
          'special-message-banner--admin': _vm.admin_imported,
          'special-message-banner--admin-mobile': _vm.show_mobile
        },attrs:{"data-cy":"special-message-banner-image"}},[(banner.link)?_c('a',{style:({
            pointerEvents: _vm.admin_imported ? 'none' : 'all'
          }),attrs:{"href":banner.external_link ? 'https://' + banner.link.replace('https://', '').replace('http://', '') : banner.link,"itemprop":"banner link","target":"_blank"}},[_c('v-lazy-image',{staticClass:"special-message-banner__image",attrs:{"src":_vm.choose_img_to_show(banner),"src-placeholder":_vm.choose_placeholder_img(banner),"alt":banner.alt,"itemprop":"image"}})],1):_c('div',[_c('v-lazy-image',{staticClass:"special-message-banner__image",attrs:{"src":_vm.choose_img_to_show(banner),"src-placeholder":_vm.choose_placeholder_img(banner),"alt":banner.alt,"itemprop":"image"}}),(_vm.admin_imported && !_vm.admin_banners.special_message_banner)?_c('div',{staticClass:"special-message-banner--admin-message md-title"},[_c('p',{attrs:{"data-cy":"special-message-text"}},[_vm._v(" "+_vm._s(_vm.$translate("special_message_banner.demo_message"))+" ")])]):_vm._e()],1)])])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }