import filter_out_unavailable from "../../../../functions/methods/other/filter_out_unavailable"
import get_curly_brace_content from "../../Shared/methods/get_curly_brace_content"

export default ({ collections = [], categories, id, buffer_id }, is_marketplace_environment) => {
  // Get first possible collection
  const collection = filter_out_unavailable(collections)[0]
  const parent_collection = collection ? get_curly_brace_content(collection.body_html) : false

  return is_marketplace_environment ? 
    `/products/${categories.join('/')}/${id}` :
    `/Products${parent_collection ? `/${parent_collection[1]}` : ""}${collection ? `/${collection.handle}` : ""}/${buffer_id || id}`
}
